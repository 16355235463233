import Flag from '@sqs/enums/Flag';
import { legacyV6Flags as BetaFeatureUtils } from '@sqs/universal-flags';
import { t } from 'shared/i18n';
import EventType from '@sqs/enums/CensusEventType';
import * as CensusUtils from 'shared/utils/census/CensusUtils';

/*
 * Inner search component of search-page.js at /search
 * For search blocks within pages, see search-preview.js
 */
YUI.add('squarespace-search', function (Y) {

  Y.namespace('Squarespace');

  var UP_KEY = 38;
  var DOWN_KEY = 40;
  var ENTER_KEY = 13;

  /**
   * Search container base class. Contains logic to do:
   * search, pagination, adding/removing filters,
   * lookup result data object from search result
   */
  Y.Squarespace.Widgets.SearchContainer = Y.Base.create(
  'searchContainer',
  Y.Squarespace.SSWidget,
  [],
  {

    bindUI: function () {
      var searchInputBox = this.get('searchInputBox');
      searchInputBox.after('queryStringChange', this._onSearchInputBoxChange, this);
      this.after('searchQueryChange', this._onSearchQueryChange, this);

      if (this.get('censusEnabled')) {
        this.after(
        'searchQueryChange',
        this._trackOnQueryChange,
        this,
        this.get('collectionFilter'));

      }

      this.after('resultItemsChange', this._onSearchResultChange, this);
      this.after('loadingChange', this.syncUI, this);
      this.get('contentBox').on('keyup', this._onKeyup, this);
    },

    /**
     * Called when search query is changed
     * - clear previous result
     * - preform a new search
     *
     * @method _onSearchQueryChange
     * @private
     */
    _onSearchQueryChange: function () {
      this._clearResult();
      this._search();
    },

    /**
     * Called when the searchFilters is updated.
     * - clear previous result
     * - preform a new search
     *
     * @method _onSearchFilterChange
     * @private
     */
    _onSearchFilterChange: function () {
      this._clearResult();
      this._search();
    },

    /**
     * Called when the search result have been updated
     * - time to syncUI
     *
     * @method _onSearchResultChange
     * @private
     */
    _onSearchResultChange: function () {
      this.syncUI();
    },

    /**
     * Cleans up the result. Shold NOT clear filters
     *
     * @method _clearResult
     * @private
     */
    _clearResult: function () {
      this.set('resultItems', null);
      this.set('page', 0);
    },

    /**
     * Called when the searchInputBox query is changed
     *
     * @method _onSearchInputBoxChange
     * @private
     */
    _onSearchInputBoxChange: function (e) {
      this.set('searchQuery', e.newVal);
    },

    /**
     * Enables keys navigatiomn
      * @method  _onKeyup
     * @param   {Event} e
     * @private
     */

    _onKeyup: function (e) {
      if (e.keyCode === UP_KEY && !this.get('searchInputBox').isAutocompleteEnabled()) {
        // up arrow
        this._moveSelectedResultItem(-1);
      } else if (e.keyCode === DOWN_KEY && !this.get('searchInputBox').isAutocompleteEnabled()) {
        // down arrow
        this._moveSelectedResultItem(1);
      } else if (e.keyCode === ENTER_KEY) {
        this._onEnter();
      }
    },

    /**
     * move the active item down or up depending on the pos parameter
     * @method  _moveSelectedResultItem
     * @param   {Number} pos
     * @private
     */
    _moveSelectedResultItem: function (pos) {
      var item = this.get('contentBox').one('.active');
      if (Y.Lang.isValue(item)) {

        item.removeClass('active');
        var items = this.get('contentBox').all('.sqs-search-ui-item');
        var index = items.indexOf(item);
        var nextNode = items.item(index + pos);
        if (Y.Lang.isValue(nextNode)) {
          nextNode.addClass('active');
          nextNode.scrollIntoView();
        }

      } else if (pos > 0) {
        var firstItem = this.get('contentBox').one('.sqs-search-ui-item');
        if (Y.Lang.isValue(firstItem)) {
          firstItem.addClass('active');
          firstItem.scrollIntoView();
        }
      }
    },

    /**
     * Opens the url of the item active when we press enter
     * @method  _onEnter
     * @private
     */
    _onEnter: function () {
      var active = this.get('contentBox').one('.active');
      if (!Y.Lang.isValue(active) && !this.get('searchInputBox').isAutocompleteEnabled()) {
        this._redirect(this._getSearchItemUrl(active));
      }
    },

    /**
     * Finds the url of a given search item
     * @method  _getSearchItemUrl
     * @param   {Node} elt the search item node
     * @private
     * @return  {String} the url of the item
     */
    _getSearchItemUrl: function (elt) {
      if (!Y.Lang.isValue(elt)) {
        return;
      }

      var itemId = elt.getAttribute('itemid');
      if (!Y.Lang.isValue(itemId)) {
        return;
      }

      var item = this._getSearchResultObject(itemId);
      return item.itemUrl;
    },

    /**
     * Render the preview dropdown
     *
     * @method _redirect
     * @param {String} url
     * @private
     */
    _redirect: function (url) {
      if (Y.Lang.isValue(url)) {
        document.location.href = url;
      }
    },

    /**
     * Performs the actual search based on the ATTR stored
     * @method _search
     * @private
     */
    _search: function () {
      var queryString = this.get('searchQuery');
      var filters = this.get('searchFilter');
      var page = this.get('page');
      var minCharsToAutosuggest = BetaFeatureUtils.isFeatureEnabled(Flag.REDUCE_GENERAL_SEARCH_API_TRAFFIC) ? 3 : 1;
      if (queryString.length < minCharsToAutosuggest) {
        return;
      }

      this.set('loading', true);

      var data = {
        q: queryString,
        p: page };


      if (Y.Lang.isValue(filters)) {
        Y.Array.each(filters, function (item) {
          data['f_' + item.name] = item.value;
        });
      }

      Y.Data.get({
        url: '/api/search/GeneralSearch',
        data: data,
        success: this._searchResultCallback,
        error: this._searchResultCallbackError },
      this);
    },

    /**
     * Success callback
     * If page > 0 it's the next search result page
     * - append the new search result to existing items
     * If not just set the searchResult data
     *
     * @method _searchResultCallback
     * @param {Object} data the response object
     * @private
     */
    _searchResultCallback: function (data) {
      this.set('loading', false);
      this.set('facets', data.facets);
      this.set('totalCount', data.totalCount);
      var oldResult = this.get('resultItems');
      var page = this.get('page');

      if (data.serviceError) {
        if (__DEV__) {
          console.error('Search error: ' + data.serviceError);
        }
      } else if (page > 0 && oldResult) {
        oldResult = oldResult.concat(data.items);
        this.set('resultItems', oldResult);
      } else {
        this.set('resultItems', data.items);
      }
    },

    /**
     *  Error handler
     *
     * @method _searchResultCallbackError
     * @private
     */
    _searchResultCallbackError: function () {
      this.set('loading', false);
      this._errorNotice = this.get('strings.errorNotice');
    },

    /**
     * Gathering data for the template
     *
     * @method _getSearchDataModel
     * @private
     * @return {Object} a data object used by our template
     */
    _getSearchDataModel: function () {
      var items = this.get('resultItems');
      var data = {
        loading: this.get('loading'),
        error: this._errorNotice,
        items: items };


      if (Y.Lang.isValue(items)) {
        if (items.length === 0) {
          data.notice = this.get('strings.emptyNotice');
        }
      } else {
        data.notice = this.get('strings.instruction');
      }

      return data;
    },

    /**
     * Lookup function to look for an item in the searchResult that exist
     * Traversing an array is not the most efficient way of doing it but good enough for now.
     * @method _getSearchResultObject
     * @private
     */
    _getSearchResultObject: function (id) {
      return Y.Array.find(this.get('resultItems'), function (item) {
        return item.id === id;
      });
    },

    /**
     * For pagination
     * Increments the page count and coes a new search
     *
     * @method _nextPage
     * @private
     */
    _nextPage: function () {
      var newPage = this.get('page') + 1;
      this.set('page', newPage);
      this._search();
    },

    /**
     * Census data
     *
     *
     * @method _trackOnQueryChange
     * @private
     */
    _trackOnQueryChange: function (e, collectionId) {
      this._trackSearchHit(e.newVal, collectionId, Y.config.doc.location.href);
    },

    /**
     * query: the search query
     * filters:
     *  collectionsIds: an array of collections the search will only look into
     *                  (for now just one collection)
     * referrer : the url we initiate the search query from
     * @method  _trackSearchHit
     * @param {String} query        the search query
     * @param {String} collectionId the collection identifier we want to filter the search by
     *                              - TODO array of ids - yguillemot
     * @param {String} referrer     [description]
     * @private
     */
    _trackSearchHit: function (query, collectionId, referrer) {
      if (!Y.Lang.isValue(query) || query === '') {
        return;
      }

      var collectionIds = [];
      if (Y.Lang.isValue(collectionId) && collectionId !== '') {
        collectionIds.push(collectionId);
      }

      CensusUtils.track(EventType.SITE_SEARCH, {
        query: query,
        filters: { collectionIds: collectionIds },
        referrer: referrer });

    } },


  {

    CSS_PREFIX: 'sqs-search-container',

    ATTRS: {
      strings: {
        value: {
          /*
           * The notice displayed when the search request throws an error
           *
           * @attribute errorNotice
           * @type {String}
           */
          errorNotice: t("Something wrong happened. Please reload."),





          /*
           * The notice displayed when the search is complete
           * and there is no result
           *
           * @attribute emptyNotice
           * @type {String}
           */
          emptyNotice: t("Your search did not match any documents."),





          /*
           * The instruction when we get to the page
           *
           * @attribute instruction
           * @type {String}
           */
          instruction: t("Begin typing your search above and press return to search.") } },







      /*
       * The containers SearchInputBox widget, where you type
       *
       * @attribute searchInputBox
       * @type {Node}
       * @default null
       */
      searchInputBox: {
        value: null },


      /*
       * Contains current filters
       * Contains object like {name: 'collectionId', value:'4f5a8877036420e36853f4de'}
       *
       * @attribute searchFilter
       * @type {Array}
       * @default []
       */
      searchFilter: {
        value: [],
        validator: Y.Squarespace.AttrValidators.isArray },


      /*
       * For Pagination, to keep track on what result page we are on
       *
       * @attribute page
       * @type {Number}
       * @default 0
       */
      page: {
        value: 0,
        validator: Y.Squarespace.AttrValidators.isNumber },


      /*
       * The current search querie
       *
       * @attribute searchQuery
       * @type {String}
       * @default ''
       */
      searchQuery: {
        value: '',
        validator: Y.Squarespace.AttrValidators.isString },


      /*
       * The search result data object that have been fetched and is being displayed
       *
       * @attribute resultItems
       * @type {Array}
       */
      resultItems: {
        validator: Y.Squarespace.AttrValidators.isNullOrArray },


      /*
       * The total count of results
       *
       * @attribute totalCount
       * @type {Number}
       * @default 0
       */
      totalCount: {
        value: 0,
        validator: Y.Squarespace.AttrValidators.isNumber },


      /*
       * The facets of the search result data object
       *
       * @attribute facets
       * @type {Array}
       * @default []
       */
      facets: {
        value: [],
        validator: Y.Squarespace.AttrValidators.isArray },


      /*
       * The loading state
       *
       * @attribute loading
       * @type {boolean}
       * @default false
       */
      loading: {
        value: false,
        validator: Y.Squarespace.AttrValidators.isBoolean },



      /*
       * The Census flag. If true, we track the user searches
       *
       * @attribute censusEnabled
       * @type {boolean}
       * @default true
       */
      censusEnabled: {
        value: true,
        validator: Y.Squarespace.AttrValidators.isBoolean } } });




  /**
   * Search field widget, used YUI autocomplete plugin for the
   * autocomplete logic
   */
  Y.Squarespace.Widgets.SearchInputBox = Y.Base.create(
  'searchInputBox',
  Y.Widget,
  [],
  {

    renderUI: function () {
      var node = this._createSearchNode();
      this.get('contentBox').append(node);
    },

    bindUI: function () {
      this._enableAutoComplete();
      var form = this.get('boundingBox').one('form');
      form.on('submit', this.onSubmit, this);
      form.on('keydown', this._onKeyDown, this);
      Y.Squarespace.Utils.onPointerAction(this.get('boundingBox').one('input'), this.focus, this);
      this.after('queryStringChange', this._resetAutoComplete, this);
    },

    /**
     * Capture all except esc button and stop propagation
     *
     * @method _onKeyDown
     * @private
     */
    _onKeyDown: function (e) {
      if (e.keyCode !== 27) {
        e.stopPropagation();
      }
    },

    /**
     * Autocomplete gets unplugged when a search is done so we get
     * access to up/down arrows again. The later is here to let the
     * autocomplete finish it's logic before we unplug it.
     * The autocomplete plugin throws an exception without it
     *
     * @method _autocompleteSelected
     * @private
     */
    _autocompleteSelected: function (e) {
      Y.later(1, this, function (event) {
        this.set('queryString', event.result.text);
      }, e);
    },

    /**
     * On form submit we set the searchString value. Container
     * will typically listen to changes here and do a search
     *
     * @method onSubmit
     * @protected
     */
    onSubmit: function (e) {
      if (Y.Lang.isValue(e)) {
        e.preventDefault();
      }
      var value = this.get('contentBox').one('input').get('value');
      this.set('queryString', value);
    },

    /**
     * Plug in the YUI autocomplete plugin
     *
     * @method _enableAutoComplete
     * @private
     */
    _enableAutoComplete: function () {
      var source = location.protocol + '//' + location.host + '/api/search/SearchSuggest';
      var searchSuggest = new Y.DataSource.IO({
        source: source });

      var inputNode = this.get('contentBox').one('input');
      if (this.get('withSearchSuggest') && !inputNode.ac) {
        inputNode.plug(Y.Plugin.AutoComplete, {
          source: searchSuggest,
          requestTemplate: '?q={query}',
          resultTextLocator: 'suggestion',
          resultListLocator: function (response) {
            return Y.JSON.parse(response[0].responseText);
          } });

        var ac = this.get('boundingBox').one('input').ac;
        ac.on('select', this._autocompleteSelected, this);
      }
    },

    /**
     * A check to see if the autocomplete list is
     * shown or not. Could not find a better hook into the plugin...
     *
     * @method isAutocompleteEnabled
     * @return {boolean} true is autocomplete is on
     */
    isAutocompleteEnabled: function () {
      var nodes = this.get('contentBox').all('.yui3-aclist-item');
      return nodes.size() > 0;
    },

    /**
     * Reloading the plugin to clear cache to avoid up/down arrow
     * trigger the flyout again. Wish .disable() would work :(
     *
     * @method _resetAutoComplete
     * @private
     */
    _resetAutoComplete: function () {
      var inputNode = this.get('boundingBox').one('input');
      inputNode.set('value', this.get('queryString'));
      if (inputNode.ac) {
        inputNode.unplug('ac');
      }
      this._enableAutoComplete();
    },

    /**
     * Focus the input, and make it selectable if there is content
     *
     * @method focus
     */
    focus: function () {
      var input = this.get('contentBox').one('input');
      input.focus();
      input.set('value', this.get('queryString'));
      if (this.get('queryString') !== '') {
        input.select();
      }
    },

    /**
     * Blur the input
     *
     * @method blur
     */
    blur: function () {
      var input = this.get('contentBox').one('input');
      input.blur();
    },

    /**
     * Create the markup.
     * Consider move this to template
     *
     * @method _createSearchNode
     * @private
     * @return {Node} the form node for the search
     */
    _createSearchNode: function () {
      var searchNode = Y.Node.create('<input/>');
      searchNode.setAttrs({
        placeholder: t("Type to search\u2026"),


        type: this.get('inputType'),
        spellcheck: false });


      searchNode.setAttribute('value', this.get('queryString'));
      var form = Y.Node.create('<form/>');
      form.append(searchNode);
      return form;
    } },


  {

    CSS_PREFIX: 'sqs-search-input',

    ATTRS: {
      /*
       * The current search query
       * @attribute queryString
       * @type String
       * @default ''
       */
      queryString: {
        value: '',
        validator: Y.Squarespace.AttrValidators.isString },


      /*
       * The input type value
       * @attribute inputType
       * @type String
       * @default 'search'
       */
      inputType: {
        value: 'search',
        validator: Y.Squarespace.AttrValidators.isString },


      /*
       * Autocomplete flag
       * @attribute withSearchSuggest
       * @type boolean
       * @default false
       */
      withSearchSuggest: {
        value: false,
        validator: Y.Squarespace.AttrValidators.isBoolean } } });




}, '1.0', {
  requires: [
  'autocomplete-plugin',
  'base',
  'datasource',
  'event',
  'squarespace-animations',
  'squarespace-ss-widget',
  'squarespace-util'] });