/*
 * This file was generated by the `create-manifests` gulp task.
 * Run `npm run gulp create-manifests` to regenerate this file.
 */
import 'src/main/webapp/universal/scripts-v6/search-page.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/autocomplete-plugin/autocomplete-plugin.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/autocomplete-list/autocomplete-list.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/autocomplete-base/autocomplete-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/selector-css3/selector-css3.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/selector-css2/selector-css2.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/shim-plugin/shim-plugin.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-position/widget-position.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-position-align/widget-position-align.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-local/datasource-local.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-io/datasource-io.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-get/datasource-get.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-function/datasource-function.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-cache/datasource-cache.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/cache-base/cache-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-jsonschema/datasource-jsonschema.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dataschema-json/dataschema-json.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dataschema-base/dataschema-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-xmlschema/datasource-xmlschema.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datatype-xml-format/datatype-xml-format.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dataschema-xml/dataschema-xml.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-arrayschema/datasource-arrayschema.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dataschema-array/dataschema-array.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-textschema/datasource-textschema.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dataschema-text/dataschema-text.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datasource-polling/datasource-polling.js';
import 'src/main/webapp/universal/scripts-v6/search.js';
import 'src/main/webapp/universal/scripts-v6/ss-widget.js';
import 'src/main/webapp/universal/scripts-v6/debugger.js';
import 'src/main/webapp/universal/scripts-v6/template-helpers.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/handlebars-base/handlebars-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/substitute/substitute.js';
import 'src/main/webapp/universal/scripts-v6/ui-templates.js';
import 'src/main/webapp/templates-v6/handlebars/search-result.html';