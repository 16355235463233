import Flag from '@sqs/enums/Flag';
import { legacyV6Flags as BetaFeatureUtils } from '@sqs/universal-flags';
import * as trackEvent from 'shared/utils/commerce/trackEvent';
import isNil from 'lodash/isNil';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/search-page.css';
/**
 * Search page widget
 * A dynamic use of the search tool
 * at /search
 *
 * @module squarespace-search-page
 */
YUI.add('squarespace-search-page', function(Y) {

  // Migrated from util.js
  var getInnerTextFromHtml = function (html) {
    var texter = Y.Node.create('<div>');
    texter.set('innerHTML', html);
    return texter.get('text');
  };


  Y.namespace('Squarespace.Widgets');

  /**
   * Search Page widget
   * The search tool in a full page
   * On first load, it provides a representation of the results from a query
   * sent by a search block.
   * It includes a dynamic load of the results when further search are done
   * through the input present on the page
   *
   * @class SearchPage
   * @constructor
   * @namespace Squarespace.Widgets
   * @extends Squarespace.Widgets.SearchContainer
   * @author yguillemot
   *
   */

  var PATH = '/search';

  var SearchPage =
    Y.Squarespace.Widgets.SearchPage =
      Y.Base.create('searchPage', Y.Squarespace.Widgets.SearchContainer, [], {

        initializer: function(config) {
          this._isFirstSync = true;
          if (this.get('censusEnabled')) {
            this._trackSearchHit(this.get('searchQuery'), this.get('collectionFilter'), Y.config.doc.referrer);
            //event param sent to null
          }
        },

        destructor: function () {

          if (this._resizeEmitter) {
            this._resizeEmitter.destroy();
            this._resizeEmitter = null;
          }

          if (Y.Lang.isValue(this._searchTypingTimeout)) {
            this._searchTypingTimeout.cancel();
          }

        },

        renderUI: function() {

          SearchPage.superclass.renderUI.apply(this, arguments);
          var inputWrapper = this.get('inputWrapper');
          var searchInputBox = new Y.Squarespace.Widgets.SearchInputBox({
            queryString: this.get('searchQuery'),
            srcNode: inputWrapper,
            boundingBox: inputWrapper,
            inputType: 'text'
          });

          this.set('searchInputBox', searchInputBox);
          searchInputBox.render().focus();

          this._resizeEmitter = new Y.Squarespace.ResizeEmitter({ timeout: 100 });

          this._initRender();

        },

        /**
     * Adapts the rendering from the results we have after page load
     * @method  _initRender
     * @private
     */
        _initRender: function() {

          var searchQuery = getInnerTextFromHtml(this.get('searchQuery'));
          this.get('inputWrapper').one('input').set('value', searchQuery);
          var noResults = !Y.Lang.isValue(this.get('resultItemEls'));

          var minCharsToAutosuggest = BetaFeatureUtils.isFeatureEnabled(Flag.REDUCE_GENERAL_SEARCH_API_TRAFFIC) ? 3 : 1;
          if (noResults && searchQuery && searchQuery.length >= minCharsToAutosuggest) {
            this.get('noticeEl').setContent(this.get('strings.emptyNotice')).toggleClass('hide');
          }

          this.get('resultsWrapperEl').toggleClass('empty', noResults);

          var collectionFilter = this.get('collectionFilter');
          if (collectionFilter !== '') {
            this.set('searchFilter', [{ name: 'collectionId', value: collectionFilter }]);
          }

          this._toggleMore(true);

          this.get('outputEl').removeClass('hide');

        },

        bindUI: function() {

          SearchPage.superclass.bindUI.apply(this, arguments);

          var resultsContainer = this.get('resultEl');
          var individualResultSelector = '.search-result';

          resultsContainer.delegate(
            'click',
            this._onResultClick,
            individualResultSelector,
            this
          );

          resultsContainer.delegate(
            'keydown',
            function(e) {
              // A link should only be activated on Enter.
              var enterPressed = e.keyCode === 13;
              if (enterPressed) {
                this._onResultClick(e);
              }
            }.bind(this),
            individualResultSelector,
            this
          );

          this.after('searchQueryChange', this._updateHistory, this);

          // auto-search
          this.get('inputWrapper').one('input').on('keyup', this._onKeyUp, this);

          var moreEl = this.get('moreEl');
          moreEl.on('click', this._nextPage, this);
          moreEl.on('keydown', function(e) {
            var enterPressed = e.keyCode === 13;
            var spacePressed = e.keyCode === 32;
            if (enterPressed || spacePressed) {
              this._nextPage(e);
            }
          }, this);

          //resize
          this._resizeEmitter.on('resize', this._onResize, this);

        },

        /**
     * Called when the searchResult have been updated.
     * Contains logic to identify if this is a new search or just the
     * next search result page, if so it just appends the new items
     * Avoids syncing on init page load
     */
        syncUI: function() {

          if (this.get('page') === 0) {
            this.get('outputEl').addClass('hide');
          }

          if (!this._isFirstSync) {

            SearchPage.superclass.syncUI.apply(this, arguments);
            this._toggleLoader();
            setTimeout(this._syncOutput.bind(this), 150);
          } else {
            this._isFirstSync = false;
            this._loadImages();
            this.get('outputEl').removeClass('hide');
          }
        },

        /**
     * Updates the search output (notice, results, pagination button). Fades in when done.
     * @method  _syncOutput
     */
        _syncOutput: function() {
          var resultsWrapperEl = this.get('resultsWrapperEl');
          var page = this.get('page');
          var perPage = this.get('perPage');
          var html = Y.Node.create(this._getResultsTemplate());

          if (Y.Lang.isValue(html)) {
            html
              .all('.sqs-search-ui-item')
              .removeClass('sqs-search-ui-item')
              .addClass('sqs-search-page-item');

            if (page === 0) {
              resultsWrapperEl.setContent(html);
            } else {

              var newList = html.get('children');
              var newListLength = newList.size();
              // splice just the number of new elements from the end
              // that's what the math is here for
              var howMany = newListLength % perPage === 0 ?
                perPage :
                newListLength % perPage;
              newList = newList.splice(-howMany);
              resultsWrapperEl.append(newList);

            }
            this.set('resultItemEls', resultsWrapperEl.get('children'));

          }

          this._loadImages();
          this._toggleNotice(html);
          this._toggleMore();
          if (!this.get('loading')) {
            this.get('outputEl').removeClass('hide');
          }
        },

        /**
     * Takes care of loading the results' images
     * @method  _loadImages
     * @private
     */
        _loadImages: function() {
          this.get('contentBox').all('img[data-src]').each(function(img) {
            ImageLoader.load(img, { load: true });
          });

        },

        /**
     * If no items we show the notice
     * @method  _toggleNotice
     * @param   {Object}  results the set of results nodes
     * @private
     */
        _toggleNotice: function(results) {

          var noticeEl = this.get('noticeEl');
          var resultsWrapperEl = this.get('resultsWrapperEl');
          var minCharsToAutosuggest = BetaFeatureUtils.isFeatureEnabled(Flag.REDUCE_GENERAL_SEARCH_API_TRAFFIC) ? 3 : 1;
          var isSearching = this.get('searchQuery') && this.get('searchQuery').length >= minCharsToAutosuggest;
          var isEmptyAndNotLoading = !Y.Lang.isValue(results) && !this.get('loading');
          var isNotEmptyOrLoading = !isEmptyAndNotLoading;

          noticeEl.toggleClass('hide', isNotEmptyOrLoading || !isSearching);
          resultsWrapperEl.toggleClass('empty', !Y.Lang.isValue(results));

          if (isEmptyAndNotLoading) {

            resultsWrapperEl.empty();
            noticeEl.setContent(this.get('strings.emptyNotice'));

          }

        },

        /**
     * Displays a loader
     * @method  _toggleLoader
     * @private
     */
        _toggleLoader: function() {

          if (this.get('searchQuery') === '') {
            this.set('loading', false);
          }

          var isLoading = Y.Lang.isValue(this.get('loading')) && this.get('loading');
          var spinnerEls = this.get('spinnerEls');

          if (isLoading && !Y.Lang.isValue(this._inputSpinner) && !Y.Lang.isValue(this._moreSpinner)) {

            this._inputSpinner = new Y.Squarespace.Spinner({
              color: 'dark',
              size: 'large',
              render: spinnerEls.item(0)
            });

            this._moreSpinner = new Y.Squarespace.Spinner({
              size: 'large',
              render: spinnerEls.item(1)
            });

          }

          this.get('inputWrapper').toggleClass('loading', isLoading);
          this.get('moreEl').toggleClass('loading', isLoading);
          spinnerEls.toggleView(isLoading);

        },

        /**
     * Displays the pagination button
     * On first init, we get data out of the elements in the dom
     * @method  _toggleMore
     * @param {boolean} isFirstInit true if it is the first initialization of the page
     * @private
     */
        _toggleMore: function(isFirstInit) {

          var moreEl = this.get('moreEl');
          if (!Y.Lang.isValue(this.get('resultItemEls')) || this.get('searchQuery') === '') {
            moreEl.addClass('hide');
            return;
          }

          var count = isFirstInit ? this.get('resultEl').getData('count') : this.get('totalCount');
          moreEl.toggleClass(
            'hide',
            count - this.get('resultItemEls').size() <= 0
          );

        },

        /**
     * Provides the visualization of the results to resync the UI
     *
     * @method _getResultsTemplate
     * @private
     * @return {String} the html of the results
     */
        _getResultsTemplate: function() {

          return Y.Squarespace.UITemplates.render(
            SearchPage.TEMPLATE_RESULTS_NAME,
            this._getSearchDataModel()
          );

        },

        /**
     * Search input key up handler
     *
     * @method _onKeyUp
     */
        _onKeyUp: function(e) {

          if (Y.Lang.isValue(this._searchTypingTimeout)) {
            this._searchTypingTimeout.cancel();
          }

          if (e.keyCode < 37 || e.keyCode > 40) {
            this._searchTypingTimeout = Y.later(
              this.get('displayDelay'),
              this,
              function() {
                this.get('searchInputBox').onSubmit();
              }
            );
          }

        },

        /**
     * Handler for when a search result is clicked
     * Opens up the item
     *
     * @method _onResultClick
     * @param {Event} e the event reference
     * @private
     */
        _onResultClick: function(e) {

          e.halt();

          var resultEl = e.target.ancestor('.search-result', true);
          var eltUrl = resultEl.getData('url');
          var url = Y.Lang.isValue(eltUrl) ? eltUrl : this._getSearchItemUrl(resultEl);

          this._trackSearchHelper(resultEl);
          this._redirect(url + '?' + Y.QueryString.stringify({
            rq: this.get('searchQuery')
          }));
        },

        /**
         * Helper function for FB pixel Search event
         *
         * @method _trackSearchHelper
         * @private
         */
        _trackSearchHelper: function(itemClicked) {

          var itemId = itemClicked.getAttribute('itemid');
          var searchResults = this.get('resultItems');
          if (isNil(searchResults)) {
            return;
          }
          var contentName;
          var contentCategory;
          var contentTag;

          for (var i = 0; i < searchResults.length; i++) {
            if (searchResults[i].id === itemId) {
              // regular expression for removing HTML from string
              contentName = searchResults[i].title.replace(/<(.|\n)*?>/g, '');
              contentCategory = searchResults[i].categories;
              contentTag = searchResults[i].tags;
              break;
            }
          }

          /* eslint-disable camelcase */
          trackEvent.trackSearch({
            content_name: contentName,
            content_category: contentCategory,
            content_tag: contentTag,
            search_string: this.get('searchQuery')
          });
          /* eslint-enable camelcase */
        },

        /**
     * History manager
     * Updates the history and url when a new search is launched
     * Allows bookmarkable results

     * @method  _updateHistory
     * @private
     */
        _updateHistory: function() {

          var searchQuery = getInnerTextFromHtml(this.get('searchQuery'));
          if (!Y.Lang.isValue(searchQuery) || searchQuery === '') {
            return;
          }

          var history = Y.config.win.history;
          var pageTitle = searchQuery + ' - ' + Y.config.doc.title;
          var state = {};
          state.q = searchQuery;

          var collectionFilter = this.get('collectionFilter');
          if (collectionFilter !== '') {
            state.f_collectionId = collectionFilter; //eslint-disable-line camelcase
          }

          history.pushState(
            state,
            pageTitle,
            PATH + '?' + Y.QueryString.stringify(state)
          );

        },

        /**
     * Handler for window resizing event
     * @method  _onResize
     * @private
     */
        _onResize: function() {
          this._loadImages();
        },

        /**
     * when we initialize or set the search query. We want to escape html tags to protect form XSS vulnerability
     * @see SEC-25
     * @method  _setSearchQuery
     * @param   {String} val the query
     * @return {String} the safe string with no html tags
     * @private
     */
        _setSearchQuery: function(val) {

          if (Y.Lang.isValue(val) && val !== '') {
            //XSS safe - no HTML unescaped special characters in the string
            val = Y.Squarespace.Escaping.escapeForHtml(val + '');
          }

          return Y.Lang.isValue(val) ? val : '';

        }

      }, {
        CSS_PREFIX: 'sqs-search-page',
        TEMPLATE_RESULTS_NAME: 'search-result.html',

        HTML_PARSER: {
          inputWrapper: '.sqs-search-page-input',
          spinnerEls: ['.spinner-wrapper'],
          outputEl: '.sqs-search-page-output',
          noticeEl: '.sqs-search-page-notice',
          resultEl: '.sqs-search-page-result',
          resultsWrapperEl: '.sqs-search-page-list .search-results',
          resultItemEls: ['.sqs-search-page-list .search-results .search-result'],
          moreEl: '.sqs-search-page-more'
        },

        ATTRS: {

          /*
       * The search query
       *
       * @attribute searchQuery
       * @type String
       * @default null
       */
          searchQuery: {
            valueFn: function() {

              var queryParam = Y.QueryString.parse(Y.config.doc.location.search.substring(1)).q;
              return this._setSearchQuery(queryParam);

            },

            setter: '_setSearchQuery',
            validator: Y.Squarespace.AttrValidators.isString
          },

          /*
       * The collection id we filter the search by -- if set up
       *
       * @attribute collectionFilter
       * @type String
       * @default null
       */
          collectionFilter: {
            valueFn: function() {
              var collectionParam = Y.QueryString.parse(Y.config.doc.location.search.substring(1)).f_collectionId;

              if (Y.Lang.isValue(collectionParam) && collectionParam !== '') {
                //XSS safe - no dom elements in the string
                collectionParam = Y.Squarespace.Escaping.escapeForHtml(collectionParam + '');
              }

              return Y.Lang.isValue(collectionParam) ? collectionParam : '';
            },
            validator: Y.Squarespace.AttrValidators.isNullOrString
          },

          /*
       * The input element wrapper
       *
       * @attribute inputWrapper
       * @type Node
       * @default null
       */
          inputWrapper: {
            value: null,
            validator: Y.Squarespace.AttrValidators.isNullOrNode,
            writeOnce: 'initOnly'
          },

          outputEl: {
            value: null,
            validator: Y.Squarespace.AttrValidators.isNullOrNode,
            writeOnce: 'initOnly'
          },

          /*
       * The notice container
       *
       * @attribute noticeEl
       * @type Node
       * @default null
       */
          noticeEl: {
            value: null,
            validator: Y.Squarespace.AttrValidators.isNullOrNode,
            writeOnce: 'initOnly'
          },

          /*
       * The spinner elements (one in the input, one in the pagination button)
       *
       * @attribute spinnerEls
       * @type NodeList
       * @default null
       */
          spinnerEls: {
            value: null,
            validator: Y.Squarespace.AttrValidators.isNullOrNodeList,
            writeOnce: 'initOnly'
          },

          /*
       * The result wrapper
       *
       * @attribute resultEl
       * @type Node
       * @default null
       */
          resultEl: {
            value: null,
            validator: Y.Squarespace.AttrValidators.isNullOrNode,
            writeOnce: 'initOnly'
          },

          /*
       * The result wrapper element
       *
       * @attribute resultItemEls
       * @type Node
       * @default null
       */
          resultsWrapperEl: {
            value: null,
            validator: Y.Squarespace.AttrValidators.isNullOrNode
          },

          /*
       * The result elements
       *
       * @attribute resultItemEls
       * @type NodeList
       * @default null
       */
          resultItemEls: {
            value: null,
            validator: Y.Squarespace.AttrValidators.isNullOrNodeList
          },

          /*
       * The pagination button
       *
       * @attribute moreEl
       * @type Node
       * @default null
       */
          moreEl: {
            value: null,
            validator: Y.Squarespace.AttrValidators.isNullOrNode,
            writeOnce: 'initOnly'
          },

          /*
       * The delay after a character was typed in the search input until it triggers the preview display
       *
       * @attribute displayDelay
       * @type {Number}
       * @default 500
       */
          displayDelay: {
            value: BetaFeatureUtils.isFeatureEnabled(Flag.REDUCE_GENERAL_SEARCH_API_TRAFFIC) ? 1000 : 500,
            validator: Y.Squarespace.AttrValidators.isNumber
          },

          /*
       * The size of each page loaded
       *
       * @attribute displaySize
       * @type {Number}
       * @default 10
       */
          perPage: {
            value: 20,
            validator: Y.Squarespace.AttrValidators.isNumber,
            writeOnce: 'initOnly'
          }
        }

      });

  // ------------------------------------------------------------------------------------------------
  // Initialize
  // ------------------------------------------------------------------------------------------------

  Y.config.win.Squarespace.onInitialize(Y, function() {
    Y.all('.sqs-search-page').each(function(node) {

      new SearchPage({
        srcNode: node
      }).render();

    });
  });

}, '1.0', { requires: [
  'autocomplete-plugin',
  'base',
  'datasource',
  'event',
  'querystring',
  'squarespace-animations',
  'squarespace-dom-emitters-resize',
  'squarespace-escaping-utils',
  'squarespace-search',
  'squarespace-search-result-template',
  'squarespace-spinner',
  'squarespace-ss-widget',
  'squarespace-ui-templates',
  'squarespace-util'
] });

