/* eslint-disable */
// Generated from seed-templates/handlebars-module-template.js
YUI.add('squarespace-search-result-template', function(Y) {
  var Handlebars = Y.Handlebars;

  (function() { var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {}; templates["search-result.html"] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n<div\n  tabindex=\"0\"\n  role=\"link\"\n  class=\"search-result clear\"\n  itemid=\"";
  if (stack1 = helpers.id) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.id; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\"\n>\n  <div class=\"sqs-search-container-item sqs-search-ui-item sqs-post ";
  stack1 = helpers['if'].call(depth0, depth0.recordTypeName, {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " ";
  if (stack1 = helpers.documentType) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.documentType; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\">\n    ";
  stack1 = helpers['if'].call(depth0, depth0.imageUrl, {hash:{},inverse:self.noop,fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    <div class=\"sqs-main-content\">\n      <div class=\"sqs-title\">";
  if (stack1 = helpers.title) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.title; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>\n      <div class=\"sqs-content\">\n        ";
  stack1 = helpers.each.call(depth0, depth0.highlight, {hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n      </div>\n    </div>\n  </div>\n</div>\n";
  return buffer;
  }
function program2(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "sqs-post-type-";
  if (stack1 = helpers.recordTypeName) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.recordTypeName; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1);
  return buffer;
  }

function program4(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    <div class=\"sqs-main-image-container\">\n      <div class=\"sqs-main-image-intrinsic\">\n        <div class=\"sqs-main-image content-fill\">\n          <img data-src=\"";
  if (stack1 = helpers.imageUrl) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.imageUrl; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\" data-image-focal-point=\""
    + escapeExpression(((stack1 = ((stack1 = depth0.mediaFocalPoint),stack1 == null || stack1 === false ? stack1 : stack1['x'])),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + ","
    + escapeExpression(((stack1 = ((stack1 = depth0.mediaFocalPoint),stack1 == null || stack1 === false ? stack1 : stack1['y'])),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"/>\n        </div>\n      </div>\n    </div>\n    ";
  return buffer;
  }

function program6(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n        <span>";
  stack1 = (typeof depth0 === functionType ? depth0.apply(depth0) : depth0);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n        <span>&hellip;</span>\n        ";
  return buffer;
  }

  stack1 = helpers.each.call(depth0, depth0.items, {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n";
  return buffer;
  });})()

  var filename = 'search-result.html';
  Y.Handlebars.registerPartial(filename.replace('/', '.'), Handlebars.templates[filename]);

}, '1.0', {
  requires: [
    "handlebars-base"
  ]
});
